@import 'src/styles/variables';

.goBack {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: $appBlack;
  margin-bottom: 20px;
  padding: 6px 12px 6px 0;

  svg {
    margin-right: 3px;
  }

  &:hover {
    color: $appBlue;
    cursor: pointer;
  }
}
