.root {
  flex-grow: 1;
  overflow-y: auto;
  background-color: #f8fafc;
}

.container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 12px;

  @media (min-width: 768px) {
    padding: 25px;
  }
}
