@import 'src/styles/mixins';
@import 'src/styles/variables';

.root {
  @include centeredFlex;
  width: 100%;
  padding: 0 8px;
  height: 44px;
  border: 1.5px solid transparent;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
  cursor: pointer;
}
.default {
  background-color: #fff;
  border-color: $appGrayLight;
  color: $appGray;
  transition: opacity 0.3s;

  &:not(:disabled) {
    @include hover-focus {
      opacity: 0.6;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.primary {
  background-color: $appBlue;
  border-color: $appBlue;
  color: #fff;
  transition: filter 0.3s;

  &:not(:disabled) {
    @include hover-focus {
      filter: brightness(90%);
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.delete {
  background-color: $errorColor;
  border-color: $errorColor;
  color: #fff;
  transition: filter 0.3s;

  &:not(:disabled) {
    @include hover-focus {
      filter: brightness(90%);
    }
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.iconContainer {
  @include centeredFlex;
  width: 20px;
  height: 20px;
}
.title {
  margin: 0 10px;

  svg {
    margin-top: 6px;
    width: 20px;
    height: 20px;
    fill: #fff;
  }
}
.preloaderContainer {
  @include centeredFlex;
  width: 20px;
  height: 20px;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
