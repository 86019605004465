@import 'src/styles/variables';

.wrapper {
  width: 500px;
  > form > div:not(:last-child) {
    margin-bottom: 12px;
  }
}

.controlWrapper {
  margin-left: auto;
  width: 100%;
  max-width: 240px;
  display: flex;

  button + button {
    margin-left: 10px;
  }
}

.membersWrapper {
  position: relative;
  & > span {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    font-size: 12px;
    color: $appBlue;
  }
}

.yesNoWrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  & > div {
    width: calc(50% - 10px);
  }
}

@media (max-width: 767px) {
  .wrapper {
    width: 440px;
  }
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
  }
}
