@import 'src/styles/variables';
.groupTitle {
  tr {
    background-color: #eef2f5;
    td {
      padding: 0 20px;
      text-align: left;
      font-size: $fontSizeBodyS;
      font-weight: 550;
      vertical-align: middle;
    }
  }
}

.groupTotal {
  tr {
    background-color: #fff;
    position: relative;
    border-top: 1.5px solid $appGrayLight;
    td {
      text-align: left;
      font-weight: 500;
      font-size: 16px;

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }

    &:hover {
      background-color: #eef2f5;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 50px;
  }
}

.groupBody {
  tr {
    background-color: #fff;
    td {
      text-align: left;
      font-weight: 400;
      font-size: $fontSizeBodyS;

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }

    &:hover {
      background-color: #eef2f5;
    }
  }
}
