@import 'src/styles/mixins';
@import 'src/styles/variables';


.filterPanel {
  @include flex($direction: row, $justify: flex-start);
  
  flex-wrap: wrap;
  
  min-height: 75px;
  margin-top: 20px;
  padding: 10px;

  box-sizing: border-box;  
  background: $insideLayoutDivColor;
  border: 1px solid $appGrayLight;
  border-radius: 4px;
}
.filterPanel > * {
  margin-right: 8px;
}

.circleListConteiner{
  @include flex($direction: row, $justify: flex-start);
}

.emptyMessage {
  font-size: 14px;
  color: $appGray;
  letter-spacing: 0.8px;
  text-align: center;
}

label {
  margin: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3D4756;
}


