*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  outline: none;
}
ul {
  list-style: none;
  -webkit-padding-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  scroll-behavior: smooth;
  line-height: 1.5;
}
p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
button {
  margin: 0;
  padding: 0;
  border: 0;
  border: none;
  background: transparent;
}
ul {
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
label {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

img {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
}
