.wrapper {
  min-width: 250px;
  max-width: 500px;
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.submitWrapper {
  margin-left: auto;
  max-width: 100px;
}
