@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  border: 1.5px solid $appGrayLight;
  border-radius: 8px;
  padding: 12px 0;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    flex: 1;
  }
}

.label {
  color: $appGray;
  font-size: 12px;
}

.value {
  font-size: 32px;
  font-weight: 300;
  color: $appBlue;
}

@media (max-width: 575px) {
  .wrapper {
    flex-direction: column;
    padding: 0 20px;

    & > div {
      padding: 12px 0;
      justify-content: center;
      &:first-child {
        border-right: none;
        border-bottom: 1.5px solid $appGrayLight;
      }
    }
  }
}
