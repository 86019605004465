.wrapper {
  margin-top: 20px;
  max-width: 400px;
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.submitWrapper {
  max-width: 200px;
}
