// ATTENTION!!! all this styles are using in ReportsTable.module.scss
@import 'src/styles/variables';

.wrapper {
  position: relative;
  overflow-x: auto;
}

.tableGeneral {
  color: $appBlack;
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
  cursor: auto;

  tr {
    height: 50px;
  }

  th {
    min-width: 160px;
  }

  .tableGeneralHeader {
    th {
      text-align: left;
      font-weight: 500;
      height: 70px;
      padding-right: 12px;

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
}
