@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: $fontSizeBodyS;
  line-height: 21px;
  color: #4f4f4f;
  cursor: pointer;
  @include disableTextSelection;
  input {
    margin-right: 10px;
  }

  input,
  label {
    cursor: pointer;
  }
}

.error {
  position: absolute;
  font-size: 10px;
  bottom: -17px;
  color: $errorColor;
}
