@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
  display: flex;
  margin-bottom: 20px;
}

.profileImage {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 120px;
    height: 120px;
    color: $appBlack;
  }

  .joinDate {
    color: $appGray;
    font-size: 12px;
  }
}

.profileInfo {
  color: $appBlack;
  margin-top: 15px;
  margin-left: 12px;

  .fullName {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 12px;
  }

  .email,
  .timeZone {
    display: flex;
    align-items: center;
    color: $appGray;
    margin-bottom: 9px;
    font-size: $fontSizeBodyS;
    font-weight: 500;

    svg {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 575px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .profileInfo {
    @include centeredFlex;
    flex-direction: column;
  }
}
