@import 'src/styles/mixins';
@import 'src/styles/variables';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: $fontSizeBodyS;
  line-height: 16px;
  padding: 4px 8px;
}

.active {
  background-color: $successColor;
  color: white;
}

.inactive {
  background-color: $errorColor;
  color: white;
}

.outlinedActive {
  border: 1px solid $successColor;
  color: $successColor;
}
.outlinedInactive {
  border: 1px solid rgba(234, 78, 61, 0.45);
  color: $errorColor;
}
