@import 'src/styles/variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
}
.shadowOverlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.transparentOverlay {
  background-color: transparent;
}

.overlayOnOpen {
  animation: fadeIn 0.3s ease-in-out;
}
.overlayOnClose {
  animation: fadeOut 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12),
    0 20px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 20px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 20px 20px rgba(0, 0, 0, 0.08);
  padding: 12px;
  border: 1px solid $appGrayLight;
  border-radius: 8px;
  z-index: 1001;
  max-width: 100%;
  max-height: 98%;
  overflow: auto;
}

.onOpen {
  animation: opening 0.3s ease-in-out;
}

.onClose {
  animation: closing 0.3s ease-in-out 0.01s;
}

@keyframes opening {
  from {
    top: 80%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@keyframes closing {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    top: 80%;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $appGrayDark;
  margin-right: 20px;
}

.closeBtn {
  margin-left: auto;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 13px;
    height: 13px;
    fill: $appGray;
    transition: transform 0.3s;
  }

  &:hover {
    svg {
      transform: scale(1.1);
      transition: transform 0.3s;
    }
  }
}

.content {
  width: 100%;
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
    max-width: 90%;
  }

  .controls {
    margin-bottom: 6px;
  }
}

@media (min-width: 576px) {
  .wrapper {
    max-width: 100%;
    padding: 24px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .wrapper {
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    max-width: 70%;
  }
}
