@import 'src/styles/variables';

.wrapper {
  border-left: 2px solid $appGrayLight;
  padding-left: 15px;
}

.infoContainer {
  display: flex;
  justify-content:space-between;
  min-width: 110px;
}

.line {
  border: 1px solid $appGrayLight;
}

.constTextWrapper {
  color: $appGray;
  font-size: 12px;
}

.textWrapper {
  color: #4f4f4f;
  font-size: 14px;
}