@import 'src/styles/mixins';

.root {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}

.menuWrapper {
  @include centeredFlex;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: -15px;
}

.titleWrapper {
  margin-left: 25px;
}

.accountWrapper {
  margin-left: auto;
}

@media (min-width: 768px) {
  .root {
    padding: 0 25px;
  }
}

@media (min-width: 1200px) {
  .root {
    height: 80px;
  }

  .menuWrapper {
    visibility: hidden;
  }

  .titleWrapper {
    margin-left: -15px;
  }
}
