.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.searchAndActionBtnWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
}

.searchWrapper {
  width: 250px;
}

.btnWrapper {
  max-width: 300px;
}
