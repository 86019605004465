@import 'src/styles/variables';

.controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  .tzFilterWrapper {
    margin-right: auto;
  }
}

.memberFilterWrapper {
  margin-right: 20px;
}

.totalTimeLogged {
  margin-top: 20px;
  color: $appGray;
  .time {
    color: $appBlack;
    font-weight: 500;
  }
}

.calendarWrapper {
  margin-top: 20px;
}
