.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  position: fixed;
  top: 60px;
  max-width: 450px;
  width: 100%;
  z-index: 9000;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  button {
    align-self: flex-end;
  }
}

.tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}

.activeTracker {
  background-color: #2aa7ff;
  color: #fff;
}

.pressContainer {
  display: flex;
  flex-direction: row;

  label {
    font-size: 24px;
    margin-right: 10px;
  }
}

.press {
  width: 62px;
  height: 62px;
  min-width: 62px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: #2aa7ff;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pressActive {
  background-color: #fff;
  color: #2aa7ff;
}

.disabledButton {
  opacity: 0.6;
  cursor: not-allowed;
}

.options {
  padding: 25px 0 40px 0;
  margin: 0 30px;
  border-top: 1px solid #eff1f5;
}

.counterContainer {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-right: 10px;
}

.counter {
  font-size: 24px;
}

.counterDay {
  font-size: 12px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 500px) {
  .counter {
    font-size: 16px;
  }
  .pressContainer {
    label {
      font-size: 16px;
    }
  }
}

@media (max-width: 360px) {
  .tracker {
    padding: 30px 10px;
  }
}
