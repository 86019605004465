@import 'src/styles/variables';

.wrapper {
  margin-top: 12px;
  width: 440px;
  & .form {
    div {
      margin-bottom: 12px;
      font-size: $fontSizeBodyS;
    }
  }

  @media (max-width: 575px) {
    width: 100%;
  }
}

.commentsWrapper {
  word-break: break-all;
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
