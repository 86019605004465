@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex-wrap: nowrap;
  overflow: hidden;
}

.infoWrapper {
  border: 1px solid $infoColor;
  background-color: rgba(0, 182, 255, 0.1);
  color: rgba(0, 182, 255, 0.5);
}

.warningWrapper {
  border: 1px solid $errorColor;
  background-color: rgba(216, 0, 39, 0.1);
  color: rgba(216, 0, 39, 0.5);
}

.icon {
  @include centeredFlex;
  & svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.content {
  @include centeredFlex;
  color: $appGrayDark;
  font-size: 12px;
}
