@import "src/styles/variables";

.root {
  position: relative;
}

.errorMessage {
  position: absolute;
  font-size: 8px;
  bottom: -16px;
  left: 0;
  color: $errorColor;
  white-space: nowrap;
}