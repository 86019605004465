@import 'src/styles/variables';
.wrapper {
  width: 440px;
  > form > div {
    margin-bottom: 12px;
  }
}

.memberField {
  font-size: $fontSizeBodyS;
}

.datesFlex {
  display: flex;
  gap: 20px;
}

.hoursAmountWrapper {
  width: 150px;
}

.submitWrapper {
  margin-top: 20px;
  max-width: 200px;
  padding-bottom: 20px;
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
  }
}
