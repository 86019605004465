@import 'src/styles/variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2001;
  animation: fadeIn 0.3s ease-in-out;
}
.shadowOverlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.transparentOverlay {
  background-color: transparent;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12),
    0 20px 20px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 20px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 20px 20px rgba(0, 0, 0, 0.08);
  padding: 20px;
  border: 1px solid $appGrayLight;
  border-radius: 8px;
  z-index: 2001;
  width: 100%;
  max-width: 95%;
  animation: opening 0.3s ease-in-out;
}
@keyframes opening {
  from {
    top: 80%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $appGrayDark;
}

.closeBtn {
  display: flex;
  margin-left: auto;
  width: 13px;
  height: 13px;
  align-items: center;
  justify-content: center;

  svg {
    width: 13px;
    height: 13px;
    fill: $appGray;
    transition: transform 0.3s;
  }

  &:hover {
    svg {
      transform: scale(1.1);
      transition: transform 0.3s;
    }
  }
}

.dialogContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.description {
  width: 100%;
  color: $appBlack;
  margin-bottom:  20px;
}

.controller {
  display: flex;
  align-self: flex-end;

  button + button {
    margin-left: 10px;
  }
}

@media (max-width: 1023px) {
  .wrapper,
  .overlay {
    animation: none;
  }
}

@media (min-width: 576px) {
  .wrapper {
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .wrapper {
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .wrapper {
    max-width: 600px;
  }
}

