.wrapper {
  max-width: 500px;
  > form > div {
    margin-bottom: 20px;
  }
}

.rateFlex {
  display: flex;
  width: 100%;
  gap: 20px;

  & > div {
    flex: 1;
  }
}

.submitWrapper {
  margin-left: auto;
  max-width: 200px;
}

.submitWrapperEditEntryForm {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  & > button:last-child {
    max-width: 200px;
  }
}
