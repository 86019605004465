@import 'src/styles/variables';

.root {
  position: relative;
  margin-bottom: 20px;
  max-width: 160px;

  .toggleWrapper {
    display: flex;
    flex-wrap: nowrap;
    background-color: $appGrayLight;
    border-radius: 20px;
    height: 32px;

    > button {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $appGrayLight;
      border-radius: 20px;
      font-size: 12px;
    }

    .activeBtn {
      height: 36px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 18%);
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 18%);
      border-radius: 20px;
      margin-top: -2px;
      outline: 0;
    }
  }
}
