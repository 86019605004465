@import '../../../../../styles/variables';

.root {
  width: 100%;
  height: 44px;
  position: relative;

  .searchIconContainer {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: $appGray;
    margin-right: 10px;

    svg {
      width: 16px;
      height: 16px;
      fill: $appGray;
    }
  }

  input {
    width: 100%;
    padding: 10px 30px;
    border: none;
    color: $appBlack;
    caret-color: $appBlack;
    font-size: $fontSizeBodyS;

    &:hover {
      cursor: text;
    }

    &::placeholder {
      color: $appGray;
    }
  }

  .closeIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;

    svg {
      width: 12px;
      height: 12px;
      fill: $appGray;
    }
  }
}

.outlinedInput {
  .searchIconContainer {
    left: 10px;
  }

  input {
    padding: 10px 30px 10px 40px;
    border: 1.5px solid $appGrayLight;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: $appGray;
    }

    &:hover {
      cursor: text;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1) inset;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    &:focus {
      border: 1.5px solid $appBlue;
      box-shadow: inset 0 2px 2px rgb(0 0 0 / 5%), 0 0 10px rgb(1 104 250 / 50%);
    }
  }

  .closeIconContainer {
    right: 10px;
  }
}
