@import 'src/styles/variables';

.subMenuTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  color: $appGray;
  margin-bottom: 36px;
  transition: color 0.3s;
  cursor: pointer;

  svg {
    margin-right: 12px;
    fill: $appGray;
    transition: fill 0.3s;
  }

  &:hover {
    color: #0168fa;

    svg {
      fill: $appBlue;
    }
  }

  .arrow {
    margin-left: auto;
    svg {
      transform: rotate(180deg);
      margin-right: 0;
      fill: $appGray;
    }
  }

  .openedSubNav {
    svg {
      transform: rotate(0);
      margin-right: 0;
      fill: $appGray;
    }
  }
}

.activeTitle {
  color: $appBlue;
  svg {
    fill: $appBlue;
  }
}

.subMenu {
  margin-top: -20px;
  margin-left: 8px;
  margin-bottom: 28px;
  padding-left: 12px;
  border-left: 1px solid $appGray;
}

.subItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .subItemLink {
    padding: 4px 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $fontSizeBodyS;
    font-weight: 500;
    color: $appGray;

    &:hover {
      color: $appBlue;
    }
  }

  .activeClassName {
    background-color: #F4F7FF;
    color: $appBlue;
  }
}
