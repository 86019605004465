@import 'src/styles/variables';

.navItem {
  margin-bottom: 36px;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  color: $appGray;
  transition: color 0.3s;

  &:hover {
    color: $appBlue;

    svg {
      fill: $appBlue;
      transition: fill 0.3s;
    }
  }

  svg {
    margin-right: 12px;
    fill: $appGray;
  }
}

.activeClassName {
  color: $appBlue;
  svg {
    fill: $appBlue;
  }
}
