@import 'src/styles/variables';

.wrapper {
  margin-top: 20px;
 
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.submitWrapper {
  max-width: 400px;
  margin-top: 30px;
}

.profileInfo {
  color: $appBlack;
  margin-top: 15px;
  margin-left: 12px;

  .fullName {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
  }

  .email,
  .timeZone {
    display: flex;
    align-items: flex-start;
    color: $appGray;
    margin-bottom: 9px;
    font-size: $fontSizeBodyS;
    font-weight: 500;

    svg {
      margin-right: 6px;
      width: 20px;
      height: 20px;
      min-width: 20px;
    }
  }
}
