.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    width: 600px;
    height: auto;
    max-width: 100%;
  }

  .navigationBlock {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}
