@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.accountWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .userAvatar {
    margin-bottom: 15px;
    svg {
      width: 150px;
      height: 150px;
    }
  }

  .actions {
    width: 250px;
    button, a {
      margin-bottom: 7px;
    }

    a {
      @include centeredFlex;
      width: 100%;
      padding: 0 8px;
      height: 44px;
      border: 1.5px solid $appGrayLight;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 550;
      line-height: 24px;
      cursor: pointer;
      background-color: #fff;
      color: $appGray;
      transition: opacity 0.3s;
    }
  }
}

.editAccountWrapper {
  flex-grow: 1;
  h4 {
    @include headLine4;
    font-weight: 400;
  }
}

@media (max-width: 575px) {
  .accountWrapper {
    margin: 0 auto;
  }
}
