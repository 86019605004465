@import 'src/styles/variables';

.optionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .value {
    color: $appGray;
    font-size: 10px;
  }
}
