@import 'src/styles/variables';
.groupTitle {
  tr {
    background-color: #eef2f5;
    td {
      padding: 0 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom: 1.5px solid $appGrayLight;
      text-align: left;
      font-size: $fontSizeBodyS;
      font-weight: 550;
      vertical-align: middle;
    }
  }
}

.groupTotal {
  tr {
    background-color: #fff;
    cursor: pointer;
    position: relative;
    td {
      text-align: left;
      font-weight: 500;
      font-size: 16px;

      &:first-child {
        padding-left: 20px;
      }
    }

    .totalLabel {
      svg {
        fill: $appGray;
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
        margin-right: 4px;
      }
    }

    .collapsedTotalLabel {
      svg {
        transform: rotate(90deg);
      }
    }

    &:hover {
      background-color: #eef2f5;
    }
  }
}

.collapsedTotal {
  td {
    &:first-child {
      border-bottom-left-radius: 20px;
    }
    &:last-child {
      border-bottom-right-radius: 20px;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 50px;
  }
}

.groupBody {
  tr {
    background-color: #fff;
    td {
      text-align: left;
      font-weight: 400;
      font-size: $fontSizeBodyS;

      &:first-child {
        padding-left: 20px;
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 20px;
        }
        &:last-child {
          border-bottom-right-radius: 20px;
        }
      }
    }
  }

  &::after {
    content: '';
    display: block;
    height: 50px;
  }
}

.collapsedGroupBody {
  display: none;
}
