@import 'src/styles/variables';

.editSelfRoleDisable {
  margin-top: 20px;
  color: $appGray;
}

.wrapper {
  max-width: 400px;
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.rolesWrapper {
  position: relative;

  .tooltip {
    position: absolute;
    left: 70px;
    top: 0;
    z-index: 1;
    color: $appGray;
  }
}

.submitWrapper {
  max-width: 200px;
}
