@import 'src/styles/mixins';
@import 'src/styles/variables';

.root {
  background-color: $appBlue;
  border-radius: 50%;
  box-shadow: 0 6px 12px rgb(0 0 0 / 35%);
  position: absolute;
  bottom: 30px;
  right: 20px;
  color: #fff;
  font-size: 34px;
  font-weight: 500;
  width: 46px;
  height: 46px;
  @include centeredFlex;
  z-index: 1;
  animation: appearance 0.3s ease-in-out;
}

@keyframes appearance {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
