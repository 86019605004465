
$medium: 575px;

.wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: $medium){
    display: block;
  }
}

.container {
  width: calc(50% - 10px);
  
  @media (max-width: $medium){
    width: 100%;
    margin-bottom: 10px;
  }
}

.containerFullWidth {
  width: 100%;
  margin-bottom: 10px;
}