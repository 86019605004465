
$medium: 575px;
$hoursAccuredTextLength: 195px;
$hoursWorkedTextLength: 110px;

.maxAccrualAmountWrapper {
  max-width: 50%;
  padding-right: 20px;
  margin-bottom: 10px;

  @media (max-width: $medium) {
    max-width: 100%;
    padding-right: 0px;
  }
}

.hoursAccuredAndHoursWorkedWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  @media (max-width: $medium){
    display: block;
  }
}

.hoursAccuredAndhoursWorkedWrapperFullWidth {
  align-items: flex-end;
  flex-wrap: wrap;
  display: block;
}

.hoursAccuredInputWrapper {
  width: 25%;

  @media (max-width: $medium){
    width: calc(100% - $hoursAccuredTextLength);
    margin-bottom: 10px;
    min-width: 37px;
  }
}

.hoursWorkedInputWrapper {
  width: 40%;

  @media (max-width: $medium){
    width: calc(100% - $hoursWorkedTextLength);
    margin-bottom: 10px;
    min-width: 37px;
  }
}

.hoursAccuredWrapper {
  display: flex;
  align-items: flex-end;
  width: 60%;
  margin-bottom: 10px;

  @media (max-width: $medium){
    width: 100%;
  }
}

.hoursWorkedWrapper {
  display: flex;
  align-items: flex-end;
  width: 40%;
  margin-bottom: 10px;

  @media (max-width: $medium){
    width: 100%;
  }
}

.textWrapper {
  padding: 10px;
  color: #4f4f4f;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

.hoursAccuredTextWrapper {
  width: 75%;

  @media (max-width: $medium){
    width: $hoursAccuredTextLength;
    margin-bottom: 10px;
  }
}

.hoursWorkedTextWrapper {
  width: 60%;

  @media (max-width: $medium){
    width: $hoursWorkedTextLength;
    margin-bottom: 10px;
  }
}

.hoursAccuredInputWrapperFullWidth {
  width: calc(100% - $hoursAccuredTextLength);
  min-width: 45px;
  margin-bottom: 10px;
}

.hoursWorkedInputWrapperFullWidth {
  width: calc(100% - $hoursWorkedTextLength);
  min-width: 45px;
  margin-bottom: 10px;
}

.hoursAccuredTextWrapperFullWidth {
  width: $hoursAccuredTextLength;
  margin-bottom: 10px;
}

.hoursWorkedTextWrapperFullWidth {
  width: $hoursWorkedTextLength;
  margin-bottom: 10px;
}

.hoursAccuredWrapperFullWidth {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  width: 100%;  
}

.hoursWorkedWrapperFullWidth {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  width: 100%;
}

.maxAccrualAmountWrapperFullWidth {
  max-width: 100%;
  margin-bottom: 10px;
}