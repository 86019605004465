.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.membersFilterWrapper {
  flex: 0 0 350px;
}

.requestBtnWrapper {
  flex: 0 0 180px;
}

.tabsWrapper {
  margin-bottom: 20px;
}
