@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import 'src/styles/reset';
@import 'src/styles/variables';

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  button,
  button:hover,
  button:active,
  button:focus {
    outline: 0;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.input-label {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: $appGray;
  margin-bottom: 4px;
  white-space: nowrap;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 44px;
  font-size: $fontSizeBodyS;
  color: $appBlack;
  caret-color: $appBlack;
  background-color: #fff;
  border: 1.5px solid $appGrayLight;
  border-radius: 8px;
  padding: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: #fff;
    border-color: $appBlue;
    outline: 0;
    box-shadow: inset 0 2px 2px rgb(0 0 0 / 5%), 0 0 7px rgb(1 104 250 / 50%);
  }

  &::placeholder {
    color: $appGray;
    opacity: 1;
  }
}

.errorInput {
  background-color: #fff;
  border-color: $errorColor;
  outline: 0;
  box-shadow: 0 0 7px $errorColor;
}