@import '../../../styles/variables';
@import '../../../styles/mixins';

.wrapper {
  @include centeredFlex;
}

.message {
  margin-top: 150px;
  font-size: 20px;
  color: $appGray;
  letter-spacing: 0.8px;
  text-align: center;
}
