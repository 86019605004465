@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  margin-right: 8px;
}

.btn {
  @include centeredFlex;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s;

  svg {
    width: 22px;
    fill: $appBlack;
    transition: fill 0.2s;
  }

  &:hover {
    background-color: $appBlue;
    svg {
      fill: #fff;
    }
  }
}

.activeBtn {
  background-color: $appBlue;
  svg {
    fill: #fff;
  }
}

.disabledBtn {
  cursor: not-allowed;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    svg {
      fill: $appBlack;
    }
  }
}

.popover {
  position: absolute;
  right: 0;
  top: 42px;
  min-width: 160px;
  padding: 5px 0;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  z-index: 1000;

  ul {
    overflow: hidden;
    li {
      text-align: left;
      white-space: nowrap;
      margin: 0 5px;
      cursor: pointer;
      color: $appBlack;
      border-radius: 4px;

      &:hover {
        background: #f8f8f8;
        color: $appBlue;
        a,
        button {
          color: $appBlue;
        }
      }

      a,
      button {
        color: $appBlack;
        display: block;
        padding: 8px 14px;
        width: 100%;
        text-align: left;
      }
    }
  }
}
