.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 460px;
  margin: 65px auto 0;

  @media (max-width: 1023px) {
    margin: 45px auto 0;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    @media (max-width: 1023px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }

  button {
    max-width: 160px;
  }
}
