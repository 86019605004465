@import 'src/styles/variables';

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 20px;
}

.datesRangeContainer {
  display: flex;
  gap: 20px;
}
.datesRangeWrapper {
  width: 220px;
}

.summaryWrapper {
  margin-bottom: 20px;
}

.tableTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.orgTimeZone {
  margin-left: 12px;
  color: $appGray;
  font-size: 12px;
}

.export {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $appBlue;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  button {
    color: $appBlue;
  }
}

.tableFilterWrapper {
  display: flex;
  justify-content: flex-end;
}
