@import 'src/styles/variables';

.wrapper {
  width: 100%;
  position: relative;
}

.errorMessage {
  position: absolute;
  font-size: 10px;
  bottom: -20px;
  color: $errorColor;
}
