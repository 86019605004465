@import 'src/styles/variables';

.wrapper {
  & :global(.react-datepicker) {
    color: $appBlack;
    border: 1px solid $appGrayLight;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  }

  & :global(.react-datepicker__header) {
    background-color: #fff;
    border-bottom: 1px solid $appGray;
  }

  & :global(.react-datepicker__current-month) {
    font-weight: 500;
  }

  & :global(.react-datepicker__day-name) {
    color: $appBlack;
    font-weight: 450;
  }

  & :global(.react-datepicker__day) {
    border-radius: 3px;
  }

  & :global(.react-datepicker__day--today) {
    span {
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  & :global(.react-datepicker__day--keyboard-selected) {
    background-color: transparent;
    color: $appBlack;
  }

  & :global(.react-datepicker__day--in-range) {
    background-color: $appBlue;
    color: #fff;
    span {
      color: white !important;
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }
  & :global(.react-datepicker__day--in-selecting-range) {
    background-color: $appBlue;
    span {
      color: white !important;
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  & :global(.react-datepicker__day--selected) {
    background-color: $appBlue;
    span {
      color: white !important;
      font-size: 13px !important;
      font-weight: 600 !important;
    }
  }

  & :global(.react-datepicker__day--disabled) {
    background-color: $appGrayLight;
    color: $appBlack;
    border-radius: 3px;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.customDay {
  font-size: 12px;
  font-weight: 400;
}
