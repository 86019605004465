.wrapper {
  width: 500px;
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.controlWrapper {
  margin-left: auto;
  width: 100%;
  max-width: 240px;
  display: flex;

  button + button {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    width: 440px;
  }
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
  }
}
