@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  input {
    display: block;
    width: 100%;
    height: 44px;
    padding: 10px;
    font-size: $fontSizeBodyS;
    font-weight: 400;
    color: $appBlack;
    background-color: #fff;
    border: 1.5px solid $appGrayLight;
    border-radius: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      background-color: #fff !important;
      border-color: $appBlue;
      outline: 0;
      box-shadow: inset 0 2px 2px rgb(0 0 0 / 5%), 0 0 7px rgb(1 104 250 / 50%);
    }

    &::placeholder {
      color: $appGrayLight;
      opacity: 1;
    }
  }

  & :global(.PhoneInputCountry) {
    @include centeredFlex;
    background-color: #fff;
    height: 44px;
    width: 80px;
    border: 1.5px solid $appGrayLight;
    border-radius: 8px;
    padding: 10px;
    margin-right: 16px;
  }
}
