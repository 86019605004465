@import 'src/styles/mixins';
@import 'src/styles/variables';

.optionLayout {
  @include flex($direction: row, $justify: space-between);
  margin-bottom: 3px;
}

.optionLabel{
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: $appBlack;
}

