@import 'src/styles/variables';

.nav {
  display: flex;
  flex-wrap: wrap;
  a {
    padding-top: 4px;
    margin: 0 20px 0 0;
    .text {
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      color: $appGray;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .activeTab {
    .text {
      color: $appBlack;
    }

    .border {
      margin-top: 12px;
      background-color: $appBlue;
      height: 4px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
}
