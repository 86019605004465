$medium: 575px;

.wrapper {
  max-width: 50%;
  padding-right: 20px;

  @media (max-width: $medium) {
    max-width: 100%;
    padding-right: 0px;
  }
}

.fullWidthWrapper {
  max-width: 100%;
}