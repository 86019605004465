@import 'src/styles/mixins';
@import 'src/styles/variables';

.customEvent {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 6px 8px;
}

.customEventViewOnly {
  cursor: default;
}

.allDayCustomEvent {
  padding: 2px 4px;
  & .description {
    font-size: 10px;
  }

  & :global(.fc-event-main) {
    height: 10px;
  }
}

.time {
  display: block;
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  color: $appBlack;
}

.description {
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: $appBlack;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendar {
  & :global(.fc) {
    color: $appGrayDark;
    border-radius: 20px;
    box-shadow: 0 60px 120px rgba(38, 51, 77, 0.05);
  }

  & :global(.fc-toolbar.fc-header-toolbar) {
    top: 0;
    background-color: #fff;
    padding: 30px;
    margin-bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 95px;
  }

  & :global(.fc .fc-scrollgrid) {
    border: 0 solid #f5f6f7;
    border-top-width: 2px;
    border-bottom-left-radius: 20px;
  }

  & :global(.fc-theme-standard th) {
    border: 2px solid #f5f6f7;
  }
  & :global(.fc .fc-highlight) {
    background: transparent;
  }

  & :global(.fc-theme-standard td) {
    border: 2px solid #f5f6f7;
  }

  & :global(.fc .fc-scrollgrid-section) {
    > * {
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }
  }

  & :global(.fc .fc-view-harness) {
    background-color: #fff;
  }

  & :global(.fc-col-header-cell) {
    height: 50px;
    vertical-align: middle;

    & a {
      cursor: default;
    }
  }

  & :global(.fc .fc-timegrid-slot-label-cushion) {
    font-size: $fontSizeBodyS;
    font-weight: 500;
  }

  & :global(.fc-direction-ltr .fc-timegrid-slot-label-frame) {
    text-align: center;
  }

  & :global(.fc-timegrid-more-link) {
    @include centeredFlex;
    max-height: 30px;
  }

  & :global(.fc-timegrid-slot) {
    height: 50px;
  }

  & :global(.fc-daygrid-event),
  :global(.fc-timegrid-event) {
    margin: 2px;
    border-width: 1.5px;
    border-radius: 5px;
  }

  & :global(.fc .fc-day-today) {
    background-color: transparent;
  }

  & :global(.fc .fc-day-disabled) {
    background-color: #fff;
  }

  & :global(.fc .fc-popover) {
    z-index: 1001;
  }

  & :global(.fc-v-event:not(.fc-event-start)) {
    border-top-width: 1px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  & :global(.fc-more-popover-misc) {
    display: none;
  }

  & :global(.fc-theme-standard .fc-popover-header) {
    background: $appGrayLighter;
  }

  & :global(.fc-timegrid-more-link-inner) {
    padding: 4px 12px;
  }
  & :global(.fc-timegrid-more-link) {
    background: $appGrayLighter;
  }
  & :global(.fc .fc-more-popover .fc-popover-body) {
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
  }
  & :global(.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end)) {
    border-width: 1.5px;
    border-radius: 5px;
  }
  & :global(.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start)) {
    border-width: 1.5px;
    border-radius: 5px;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  color: $appGrayDark;

  .dateOfMonth {
    font-size: 38px;
    line-height: 1;
    margin-right: 8px;
    font-weight: 400;
  }

  .today {
    color: #fff;
    @include centeredFlex;
    background-color: rgb(93, 63, 211);
    font-size: 26px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
  .monthAndDay {
    @include centeredFlex;
    flex-direction: column;
    gap: 5px;
    & > div {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
    }

    .month {
      color: $appGray;
    }
  }
}

.dayCell {
  font-size: 16px;
  font-weight: 500;
}

.dayCellToday {
  color: #fff;
  @include centeredFlex;
  background-color: rgb(93, 63, 211);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-weight: 500;
  font-size: $fontSizeBodyS;
}

@media (max-width: 1023px) {
  .calendar {
    & :global(.fc .fc-toolbar-title) {
      font-size: 18px;
    }
  }
}
