.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;

  .searchWrapper {
    width: 250px;
  }
}
