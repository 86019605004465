@import 'src/styles/variables';
.wrapper {
  width: 500px;
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .wrapper {
    width: 100%;
    > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
