.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2001;
}

.menu {
  background-color: white;
  width: 340px;
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  z-index: 2002;
  display: flex;
  flex-direction: column;
  transform: translateX(105%);
  transition: transform 0.3s;
  box-shadow: -4px 4px 16px 0 rgb(0 0 0 / 10%);
}

.show {
  transform: translateX(0);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    transform: translateX(105%);
  }
  to {
    transform: translateX(0);
  }
}

.content {
  padding: 25px 30px;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (min-width: 1200px) {
  .menu {
    top: 80px;
  }
}
