.root {
  height: 100%;
  display: flex;
}

.sidebarWrapper {
  width: 0;

  @media (min-width: 1200px) {
    flex-shrink: 0;
    width: 290px;
    height: 100%;
  }
}

.navbarAndContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1200px) {
    width: calc(100% - 290px);
  }
}
