@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  background-color: #fff;
  border: 1px solid $appGrayLight;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;
  transition: border 0.1s, transform 0.1s, box-shadow 0.1s;
  width: 250px;

  &:hover {
    border-color: transparent;
    transform: scale(1.01);
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 0 4px 2px rgb(0 0 0 / 4%);
  }
}

.title {
  @include headLine6;
  color: $appBlack;
  margin-bottom: 4px;
}

.description {
  color: $appGray;
  font-size: $fontSizeBodyXS;
}
