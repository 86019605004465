.link {
  display: flex;
  justify-content: space-between;
  width: 144px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #e4e9ef;
  padding: 0 10px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2aa7ff;
  cursor: pointer;

  svg {
    fill: #64748B;
    width: 16px;
  }
}

.arrow {
  transform: rotate(90deg);
}

@media (max-width: 500px) {
  .link {
    margin-left: 0;
  }
}