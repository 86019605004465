@import 'src/styles/mixins';

.pageTitle {
  @include headLine6;
}

@media (min-width: 768px) {
  .pageTitle {
    @include headLine5;
  }
}

@media (min-width: 1200px) {
  .pageTitle {
    @include headLine4;
  }
}
