@import 'src/styles/variables';

.root {
  position: relative;
  .label {
    color: $appGray;
    margin-bottom: 7px;
    font-size: 12px;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 100px;
  }

  .errorMessage {
    position: absolute;
    font-size: 8px;
    bottom: -16px;
    left: 0;
    color: $errorColor;
    white-space: nowrap;
  }
}
