@import "src/styles/variables";

.wrapper {
  margin-top: 20px;
  max-width: 400px;
  > form > div:not(:last-child) {
    margin-bottom: 20px;
  }
}

.membersWrapper {
  position: relative;
  & > span {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    font-size: 12px;
    color: $appBlue;
  }
}

.submitWrapper {
  max-width: 200px;
}
