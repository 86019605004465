@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapperWithoutDescription {
  position: relative;
}

.wrapperWithDescription {
  position: relative;
  display: grid;
  gap: 0;
  grid-template-columns: 40% 60%;
  align-items: center;
}

.description {
  @include centeredFlex;
  height: 100%;
  min-width: 50px;
  font-size: 12px;
  color: #4f4f4f;
  background-color: $appGrayLight;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.textInputWrapperWithDescription {
  z-index: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.errorMessage {
  position: absolute;
  font-size: 8px;
  bottom: -16px;
  left: 0;
  color: $errorColor;
  white-space: nowrap;
}