@import 'src/styles/mixins';
@import 'src/styles/variables';

.circleContainer {
  margin-right: -10px; 
}

.circle {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border: 2px solid white; 
  transition: transform 0.2s, z-index 0.2s;
}

.circle:hover {
  transform: scale(1.1);
  z-index: 100;
}

.selected {
  border: 2px solid #00B6FF;
}






