@import 'src/styles/mixins';
@import 'src/styles/variables';
.title {
  @include headLine5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid $appGrayLight;
}
.table {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 500px;
  margin-bottom: 20px;

  .row {
    padding: 0 20px;
    display: flex;
    width: 100%;
    height: 40px;
  }

  .bodyRow {
    &:hover {
      background-color: rgba(211, 211, 211, 0.2);
      cursor: pointer;
    }
  }

  .cell {
    flex: 1 1;
    display: flex;
    align-items: center;
    min-height: inherit;
  }

  .bodyCell {
    font-size: 14px;
    overflow: hidden;
  }

  .bodyCellContent {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .headerCell {
    @include headLine6;
    color: $appGray;
  }

  .body {
    flex-grow: 1;
  }
}
