@import 'src/styles/mixins';

.overlay {
  @include centeredFlex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1002;
}
