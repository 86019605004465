@import 'src/components/timesheets/AddTimeForm/AddTimeForm.module';

.controlWrapper {
  margin-left: unset;
  max-width: unset;
  width: 100%;
  display: flex;

  .deleteBtn {
    max-width: 44px;
    margin-right: auto;
  }

  .cancelBtn {
    min-width: 100px;
  }

  .saveBtn {
    margin-left: 10px;
    min-width: 100px;
  }
}
