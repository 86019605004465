@import 'src/styles/variables';
@import 'src/styles/mixins';

.fTable {
  color: $appBlack;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .fTableBody {
    flex-grow: 1;
  }

  .fRow {
    display: flex;
    width: 100%;
    height: 70px;
    padding: 0 16px;
    border-bottom: 1px solid $appGrayLighter;
  }

  .fHeaderRow {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $grayTableHeader;
  }

  .fBodyRow {
    background-color: #fff;

    &:hover {
      background-color: rgba(211, 211, 211, 0.1);
      cursor: pointer;
    }
  }

  .fCell {
    flex: 1 1;
    display: flex;
    align-items: center;
    min-height: inherit;
    padding-left: 12px;

    &:not(:first-child) {
      justify-content: center;
    }

    &:not(:last-child) {
      overflow: hidden;
      white-space: nowrap;
      justify-content: center;
    }

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
      max-width: 80px;
      padding-right: 12px;
    }
  }

  .fHeaderCell {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .fBodyCell {
    font-size: 14px;
  }

  .fBodyCellContent {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .membersTableAboutCell {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      max-width: 100%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }

  .membersTablePaymentsCell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span {
      display: block;
      font-size: 12px;
    }

    @media (max-width: 1439px) {
      display: none;
    }
  }

  .noDataCellContent {
    color: $appGray;
  }

  .datesContent {
    font-size: $fontSizeBodyS;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
  }

  .fewMembersWrapper {
    @include centeredFlex;
  }

  .remainingMembers {
    @include centeredFlex;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 12px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
    margin-left: 10px;
  }

  .disabledRow {
    background: #eff1f5;
    filter: grayscale(1);
    opacity: 0.6;
    pointer-events: none;

    &:hover {
      background: #eff1f5;
      filter: grayscale(1);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.requestTable {
  .fCell {
    &:not(:first-child) {
      padding-left: 12px;
    }

    &:not(:last-child) {
      overflow: hidden;
      white-space: nowrap;
      padding-left: 12px;
    }
  }
}

.loadNextTableIndicator {
  @include centeredFlex;
}

.requestsTableStatusCell {
  @media (max-width: 1439px) {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .fCell {
    &:not(:first-child, :nth-child(2), :last-child) {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .fCell {
    &:not(:first-child, :last-child) {
      display: none;
    }
  }
}
