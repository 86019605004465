//App Colours
$appBlack:                        #1B1B1B;;
$appBlue:                         #007AFF;
$appBlueLight:                    #eff6ff;
$appGray:                         #64748B;
$appGrayLight:                    #E7E7E7;
$appGrayLighter:                  #e8e8e8;
$appGrayDark:                     #54595E;
$grayTableHeader:                 #eef2f5;
$successColor:                    #27C79A;
$warningColor:                    #FFD02B;
$errorColor:                      #D80027;
$infoColor:                       #00B6FF;

$insideLayoutDivColor:                      #FFFFFF;
//App Font Sizes
$fontSizeBody:                    16px;
$fontSizeBodyXS:                  12px;
$fontSizeBodyS:                   14px;
$fontSizeBodyL:                   18px;

