@import 'src/styles/mixins';

.aside {
  width: 290px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.showMobileSidebar {
  transform: translateX(0);
  transition: transform 0.3s;
}

.asideHeader {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 24px;
  background-color: #fff;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);

  .logo {
    @include centeredFlex;
    cursor: pointer;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: -20px;
    cursor: pointer;
  }
}

.asideBody {
  flex-grow: 1;
  padding: 36px 24px;
  background-color: #fff;
  overflow-y: auto;
}

.backDropOn {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.5;
  z-index: 1000;
  visibility: visible;
  transition: opacity, visibility, background-color 0.3s;
}

.backDropOff {
  visibility: hidden;
}

@media (min-width: 1200px) {
  .aside {
    transform: translateX(0);
    border-right: 1px solid rgba(72, 94, 144, 0.16);

    .asideHeader {
      height: 80px;
    }

    .menu {
      display: none;
    }
  }

  .backDropOn {
    display: none;
  }

  .backDropOff {
    display: none;
  }
}
