@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  .picker {
    width: 100%;
    min-width: 125px;
    position: relative;

    & :global(.react-datepicker) {
      color: $appBlack;
      border: 1px solid $appGrayLight;
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    }

    & :global(.react-datepicker__time-container) {
      width: 120px;
    }

    & :global(.react-datepicker__header) {
      display: none;
    }

    & :global(.react-datepicker__time) {
      border-radius: 0.3rem;
    }

    & :global(.react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box) {
      padding: 5px;
      width: 100%;
    }

    & :global(.react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item) {
      border-radius: 6px;
    }

    & :global(.react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected) {
      background-color: $appBlue;
    }

    & :global(.react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--disabled) {
      cursor: not-allowed;
    }
  }
}

.nextDayTooltip {
  @include centeredFlex;
  position: absolute;
  width: 50px;
  height: 18px;
  top: -12px;
  right: -10px;
  background-color: $appBlue;
  color: white;
  font-size: 8px;
  font-weight: 600;
  border-radius: 8px;
}

.futureError {
  position: absolute;
  font-size: 10px;
  bottom: -20px;
  left: 0;
  color: $errorColor;
}
